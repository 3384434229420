<!--  -->
<template>
  <div class="layout">
    <div class="alertContainer">
      <img src="@/assets/alert.jpg" class="alert-icon" />
      <span class="alertText">请在微信客户端打开链接</span>
    </div>
    <div class="btnContainer">
      <el-button plain round>
        <span class="btnText" @click="goToUrl"> 在微信里打开 </span>
      </el-button>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  created() {
    console.log("自动跳转");
    // this.goToUrl();
  },
  methods: {
    goToUrl() {
      console.log('跳转');
      location.href = 'weixin://dl/business/?t=QN97XolPfIs';
    },
  },
};
</script>
<style scoped>
.layout,
.alertContainer,
.btnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.alertText {
  font-weight: 500;
  color: #000000;
  font-size: 16px;
}

.alertContainer {
  margin-top: 118px;
}
.alert-icon {
  width: 60px;
  height: 60px;
  margin-bottom: 30px;
}
.btnText {
  font-weight: 400;
  color: #5b8fd9;
  font-size: 16px;
  padding: 11px 82px;
}

.btnContainer {
  margin-top: 172px;
}
</style>
